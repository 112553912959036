import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { Checklist } from "types";

export interface ChecklistState {
  value: Checklist;
}

declare const window: {
  checklist: Checklist;
} & Window;

const initialState: ChecklistState = {
  value: window.checklist,
};

export const checklistSlice = createSlice({
  name: "checklist",
  initialState,
  reducers: {
    update: (state, action: PayloadAction<Checklist>) => {
      state.value = action.payload;
    },
    deleteCategory: (state, action: PayloadAction<string>) => {
      state.value.categories = state.value.categories.filter(
        (category) => category.id != action.payload,
      );
    },
    updateCategory: (
      state,
      action: PayloadAction<{ id: string; newName: string }>,
    ) => {
      const { id, newName } = action.payload;
      const category = state.value.categories.find(
        (category) => category.id === id,
      );
      if (!category) {
        throw new Error("No category found");
      }
      category.name = newName;
    },
    deleteItem: (
      state,
      action: PayloadAction<{ categoryId: string; itemId: string }>,
    ) => {
      const { categoryId, itemId } = action.payload;
      const category = state.value.categories.find(
        (category) => category.id === categoryId,
      );

      if (!category) {
        throw new Error("No category found");
      }

      category.items = category.items.filter((item) => item.id != itemId);

      if (category.items.length == 0) {
        state.value.categories = state.value.categories.filter(
          (category) => category.id != categoryId,
        );
      }
    },
    updateItemName: (
      state,
      action: PayloadAction<{
        id: string;
        newName: string;
      }>,
    ) => {
      const { id, newName } = action.payload;

      const item = state.value.categories
        .flatMap((_) => _.items)
        .find((_) => _.id == id);

      if (!item) {
        throw new Error("No item found");
      }
      item.name = newName;
    },
    updateCategoryOfItem: (
      state,
      action: PayloadAction<{
        itemId: string;
        newCategoryId: string;
        newCategoryName: string;
      }>,
    ) => {
      const { itemId, newCategoryId, newCategoryName } = action.payload;

      const oldCategory = state.value.categories.find((_) =>
        _.items.find((_) => _.id === itemId),
      );

      if (!oldCategory) {
        throw new Error("Old category not found");
      }

      const item = oldCategory.items.find((_) => _.id === itemId);

      if (!item) {
        throw new Error("Item not found");
      }

      if (oldCategory.items.length == 1) {
        state.value.categories = state.value.categories.filter(
          (category) => category.id != oldCategory.id,
        );
      } else {
        oldCategory.items = oldCategory.items.filter(
          (item) => item.id != itemId,
        );
      }

      let newCategory = state.value.categories.find(
        (_) => _.id === newCategoryId,
      );

      const newItem = {
        id: itemId,
        name: item.name,
        position: 0,
        checked: item.checked,
        category: newCategoryName,
        checked_summary: item.checked_summary,
        category_id: newCategoryId,
      };

      if (newCategory) {
        const newItemPosition = newCategory.items.length + 1;
        newItem.position = newItemPosition;
        newCategory.items.push(newItem);
      } else {
        const newCategoryPosition = state.value.categories.length + 1;
        newCategory = {
          id: newCategoryId,
          name: newCategoryName,
          position: newCategoryPosition,
          items: [newItem],
        };
        state.value.categories.push(newCategory);
      }
    },
    checkUncheckItem: (
      state,
      action: PayloadAction<{
        id: string;
        checked: boolean;
        checkedSummary: string;
      }>,
    ) => {
      const { id, checked, checkedSummary } = action.payload;

      const item = state.value.categories
        .flatMap((_) => _.items)
        .find((_) => _.id == id);

      if (!item) {
        throw new Error("No item found");
      }

      item.checked = checked;
      item.checked_summary = checkedSummary;
    },
  },
});

export const {
  update,
  deleteCategory,
  updateCategory,
  deleteItem,
  updateItemName,
  updateCategoryOfItem,
  checkUncheckItem,
} = checklistSlice.actions;

export default checklistSlice.reducer;
