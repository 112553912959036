import React from "react";
import { Item } from "types";

export const ChecklistItemForDragOverlay = (props: {
  item: Item;
  isRun: boolean;
}) => {
  const item = props.item;

  const checkboxTag = () => {
    if (props.isRun) {
      return (
        <input
          type="checkbox"
          name="check"
          id="check"
          defaultChecked={item.checked}
          className="form-check-input me-1 js-checklist-item-check"
          data-item-id={item.id}
          onChange={() => {}}
        ></input>
      );
    }
  };

  return (
    <li className="list-group-item" id={`checklist-item-${item.id}`}>
      {checkboxTag()}
      <span>
        <a href={`/checklist_items/${item.id}/edit`} title="Edit">
          {item.name}
        </a>
        <i className="bi-arrow-down-up"></i>
        {item.checked_summary}
      </span>
    </li>
  );
};
