import React, { useState, SyntheticEvent } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { putRequest, deleteRequest } from "helpers";
import { Item, Authorization } from "types";
import { useDispatch } from "react-redux";
import {
  deleteItem,
  updateItemName,
  updateCategoryOfItem,
  checkUncheckItem,
} from "checklistSlice";

export const ChecklistItem = ({
  item,
  authorization,
  isRun,
}: {
  item: Item;
  authorization: Authorization;
  isRun: boolean;
}) => {
  const showMode = "showMode";
  const editNameMode = "editNameMode";
  const editCategoryMode = "editCategoryMode";
  const [mode, setMode] = useState(showMode);
  const [newName, setNewName] = useState("");
  const [newCategory, setNewCategory] = useState("");
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
      id: item.id,
      transition: null,
    });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const dispatch = useDispatch();

  const checkItem = (item: Item) => {
    putRequest(`/checklist_items/${item.id}/check`, {
      checked: !item.checked,
    }).then((jsonResponse) => {
      dispatch(
        checkUncheckItem({
          id: item.id,
          checked: !item.checked,
          checkedSummary: jsonResponse.checked_summary,
        }),
      );
    });
  };

  const checkboxTag = () => {
    if (isRun && authorization.can_edit_checklist_item) {
      return (
        <input
          type="checkbox"
          name="check"
          id={`check-${item.id}`}
          defaultChecked={item.checked}
          className="form-check-input me-1 js-checklist-item-check"
          data-item-id={item.id}
          onChange={() => {
            checkItem(item);
          }}
        ></input>
      );
    }
  };

  const enableShowMode = (event: SyntheticEvent) => {
    event.preventDefault();
    setMode(showMode);
  };

  const enableEditNameMode = (event: SyntheticEvent) => {
    event.preventDefault();

    setNewName(item.name);
    setMode(editNameMode);
  };

  const enableEditCategoryMode = (event: SyntheticEvent) => {
    event.preventDefault();

    setNewCategory(item.category);
    setMode(editCategoryMode);
  };

  const updateChecklistItem = (event: SyntheticEvent) => {
    event.preventDefault();

    putRequest(`/checklist_items/${item.id}`, {
      name: newName,
      category: item.category,
    }).then(() => {
      dispatch(
        updateItemName({
          id: item.id,
          newName: newName,
        }),
      );
      enableShowMode(event);
    });
  };

  const updateChecklistItemCategoryName = (event: SyntheticEvent) => {
    event.preventDefault();

    if (item.category != newCategory) {
      putRequest(`/checklist_items/${item.id}`, {
        name: item.name,
        category: newCategory,
      }).then((jsonResponse) => {
        dispatch(
          updateCategoryOfItem({
            itemId: item.id,
            newCategoryId: jsonResponse.category_id,
            newCategoryName: newCategory,
          }),
        );
      });
    }
    enableShowMode(event);
  };

  const deleteChecklistItem = (event: SyntheticEvent) => {
    event.preventDefault();

    deleteRequest(`/checklist_items/${item.id}`).then(() => {
      dispatch(deleteItem({ categoryId: item.category_id, itemId: item.id }));
    });
  };

  const showView = () => {
    return (
      <span>
        {checkboxTag()}
        {item.name}
        {authorization.can_edit_checklist_item && (
          <i className="bi-arrow-down-up" {...listeners} {...attributes}></i>
        )}
        {authorization.can_edit_checklist_item && (
          <a onClick={enableEditNameMode} href="#" title="Edit">
            <i className="bi-pencil-square"></i>
          </a>
        )}
        {!authorization.can_edit_checklist_item && <>&nbsp;</>}
        {item.checked_summary}
      </span>
    );
  };

  const editNameView = () => {
    return (
      <form onSubmit={updateChecklistItem}>
        <div className="row mb-2 g-0">
          <div className="col-auto">
            <input
              className="form-control form-control-sm"
              type="text"
              id="item-name"
              name="name"
              value={newName}
              onChange={(e) => setNewName(e.target.value)}
            />
          </div>
          <div className="col-auto">
            <a
              onClick={updateChecklistItem}
              title="Save new item name"
              href="#"
            >
              <i
                className="bi-check text-success"
                style={{ fontSize: "1.5rem" }}
              ></i>
            </a>
            <a onClick={enableShowMode} title="Cancel editing" href="#">
              <i
                className="bi-x text-warning"
                style={{ fontSize: "1.5rem" }}
              ></i>
            </a>
            <a
              onClick={deleteChecklistItem}
              title="Delete item"
              href="#"
              className="me-1"
            >
              <i
                className="bi-trash text-danger"
                style={{ fontSize: "1.5rem" }}
              ></i>
            </a>
          </div>
          <div className="col-auto">
            <a
              href="#"
              onClick={enableEditCategoryMode}
              className="align-text-top"
            >
              Edit category
            </a>
          </div>
        </div>
      </form>
    );
  };

  const editCategoryView = () => {
    return (
      <form onSubmit={updateChecklistItemCategoryName}>
        <div className="row mb-2 g-0">
          <div className="col-auto">
            <label
              className="form-label align-text-top me-1"
              htmlFor={`item-${item.id}-category`}
            >
              category for {item.name}:
            </label>
          </div>
          <div className="col-auto">
            <input
              className="form-control form-control-sm"
              type="text"
              id={`item-${item.id}-category`}
              name="category"
              value={newCategory}
              onChange={(e) => setNewCategory(e.target.value)}
            />
          </div>
          <div className="col-auto">
            <a
              onClick={updateChecklistItemCategoryName}
              title="Save new item category"
              href="#"
            >
              <i
                className="bi-check text-success"
                style={{ fontSize: "1.5rem" }}
              ></i>
            </a>
            <a onClick={enableShowMode} title="Cancel editing" href="#">
              <i
                className="bi-x text-warning"
                style={{ fontSize: "1.5rem" }}
              ></i>
            </a>
          </div>
        </div>
      </form>
    );
  };

  return (
    <li
      className="list-group-item"
      id={`checklist-item-${item.id}`}
      ref={setNodeRef}
      style={style}
    >
      {mode === showMode && showView()}
      {mode === editNameMode && editNameView()}
      {mode === editCategoryMode && editCategoryView()}
    </li>
  );
};
