import React, { useState, SyntheticEvent } from "react";
import { ChecklistItems } from "./checklist_items";
import { putRequest, deleteRequest } from "helpers";
import { Category, Authorization } from "types";
import { useDispatch } from "react-redux";
import { deleteCategory, updateCategory } from "checklistSlice";

export const ChecklistItemCategory = (props: {
  category: Category;
  authorization: Authorization;
  noCategories: boolean;
  isRun: boolean;
}) => {
  const showMode = "showMode";
  const editNameMode = "editNameMode";
  const [mode, setMode] = useState(showMode);
  const [newName, setNewName] = useState("");
  const dispatch = useDispatch();

  const canEditChecklistItemCategory = () => {
    return props.authorization.can_edit_checklist_item_category;
  };

  const canDestroyChecklistItemCategory = () => {
    return props.authorization.can_destroy_checklist_item_category;
  };

  const editLink = () => {
    if (canEditChecklistItemCategory()) {
      return (
        <a href="#" title="Edit category" onClick={enableEditNameMode}>
          <i className="bi-pencil-square"></i>
        </a>
      );
    }
  };

  const deleteCategoryRequest = (event: SyntheticEvent) => {
    event.preventDefault();

    deleteRequest(`/checklist_item_categories/${props.category.id}`).then(() =>
      dispatch(deleteCategory(props.category.id)),
    );
  };

  const updateCategoryName = (event: SyntheticEvent) => {
    event.preventDefault();

    putRequest(`/checklist_item_categories/${props.category.id}`, {
      name: newName,
    }).then(() => {
      dispatch(updateCategory({ id: props.category.id, newName: newName }));
      enableShowMode(event);
    });
  };

  const destroyLink = () => {
    if (canDestroyChecklistItemCategory()) {
      return (
        <a
          onClick={deleteCategoryRequest}
          href="#"
          title="Delete category"
          style={{ fontSize: "1.5rem" }}
        >
          <i className="bi-trash"></i>
        </a>
      );
    }
  };

  const enableShowMode = (event: SyntheticEvent) => {
    event.preventDefault();
    setMode(showMode);
  };

  const enableEditNameMode = (event: SyntheticEvent) => {
    event.preventDefault();

    setNewName(props.category.name || "");
    setMode(editNameMode);
  };

  const showView = () => {
    return (
      <h4 id={`checklist-item-category-${props.category.id}`}>
        {props.category.name || "other"}
        {editLink()}
      </h4>
    );
  };

  const editNameView = () => {
    return (
      <form onSubmit={updateCategoryName}>
        <div
          className="row mb-2 g-0"
          id={`checklist-item-category-${props.category.id}`}
        >
          <div className="col-auto">
            <input
              className="form-control form-control-sm"
              type="text"
              id="category-name"
              name="category-name"
              value={newName}
              onChange={(e) => setNewName(e.target.value)}
            />
          </div>
          <div className="col-auto">
            <a onClick={updateCategoryName} title="Save" href="#">
              <i
                className="bi-check text-success"
                style={{ fontSize: "1.5rem" }}
              ></i>
            </a>
            <a onClick={enableShowMode} title="Cancel editing" href="#">
              <i
                className="bi-x text-warning"
                style={{ fontSize: "1.5rem" }}
              ></i>
            </a>
            {destroyLink()}
          </div>
        </div>
      </form>
    );
  };

  return (
    <>
      {!props.noCategories && mode === showMode && showView()}
      {!props.noCategories && mode === editNameMode && editNameView()}
      <ChecklistItems
        items={props.category.items}
        categoryId={props.category.id}
        isRun={props.isRun}
        authorization={props.authorization}
      />
    </>
  );
};
