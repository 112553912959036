import React from "react";
import { ChecklistItemCategories } from "./checklist_item_categories";
import NewChecklistItemForm from "./new_checklist_item_form";
import type { RootState } from "store";
import { useSelector } from "react-redux";

export const ChecklistRoot = () => {
  const checklist = useSelector((state: RootState) => state.checklist.value);
  const authorization = checklist.authorization;

  return (
    <>
      {authorization.can_create_checklist_item && <NewChecklistItemForm />}
      <ChecklistItemCategories />
    </>
  );
};
