import { store } from "store";

document.addEventListener(
  "DOMContentLoaded",
  initializeCopyChecklistToClipboardButton,
);

function initializeCopyChecklistToClipboardButton() {
  const copyChecklistToClipboardButton = document.getElementById(
    "js-copy-checklist-to-clipboard",
  );

  if (!copyChecklistToClipboardButton) {
    return;
  }

  copyChecklistToClipboardButton.addEventListener("click", function () {
    const clipboardItem = new ClipboardItem({
      "text/plain": new Blob([plaintextChecklist()], { type: "text/plain" }),
      "text/html": new Blob([htmlChecklist()], { type: "text/html" }),
    });

    navigator.clipboard.write([clipboardItem]);
  });

  function plaintextChecklist() {
    if (store.getState().checklist.value.categories.length > 1) {
      return checklistCategoryAndItemNames()
        .flatMap((category) => {
          return [`* ${category.name || "other"}`].concat(
            category.itemNames.map((name) => `  * ${name}`),
          );
        })
        .join("\r\n");
    } else {
      return checklistItemNames()
        .map((name) => `* ${name}`)
        .join("\r\n");
    }
  }

  function htmlChecklist() {
    let listItems;
    if (store.getState().checklist.value.categories.length > 1) {
      listItems = checklistCategoryAndItemNames()
        .flatMap((category) => {
          return [
            `<li>${category.name || "other"}</li>`,
            `<li><ul>${namesAsListItems(category.itemNames)}</ul></li>`,
          ];
        })
        .join("\r\n");
    } else {
      listItems = namesAsListItems(checklistItemNames());
    }

    const unorderedList = `<ul>${listItems}</ul>`;
    return unorderedList;
  }

  function namesAsListItems(names: Array<string>) {
    return names.map((name) => `<li>${name}</li>`).join("\r\n");
  }

  function checklistItemNames(): Array<string> {
    return store
      .getState()
      .checklist.value.categories.flatMap((category) =>
        category.items.map((item) => item.name),
      );
  }

  function checklistCategoryAndItemNames() {
    return store.getState().checklist.value.categories.map((category) => {
      return {
        name: category.name,
        itemNames: category.items.map((item) => item.name),
      };
    });
  }
}
