import React from "react";
import { ChecklistItem } from "./checklist_item";
import {
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { Item, Authorization } from "types";

export const ChecklistItems = (props: {
  items: Array<Item>;
  authorization: Authorization;
  isRun: boolean;
  categoryId: string;
}) => {
  const listItems = props.items.map((item) => (
    <ChecklistItem
      key={item.id}
      item={item}
      isRun={props.isRun}
      authorization={props.authorization}
    />
  ));

  return (
    <ul className="list-group list-group-flush">
      <SortableContext
        id={props.categoryId}
        items={props.items}
        strategy={verticalListSortingStrategy}
      >
        {listItems}
      </SortableContext>
    </ul>
  );
};
