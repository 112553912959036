import React, { useState, FormEvent } from "react";
import { postRequest } from "helpers";
import type { RootState } from "store";
import { useSelector, useDispatch } from "react-redux";
import { update } from "checklistSlice";

export default () => {
  const [disabled, setDisabled] = useState(false);
  const [name, setName] = useState("");
  const [category, setCategory] = useState("");

  const checklist = useSelector((state: RootState) => state.checklist.value);
  const dispatch = useDispatch();

  const categoriesList = checklist.categories.map((category) => (
    <option value={category.name} key={category.id}></option>
  ));

  const handleSubmit = (event: FormEvent) => {
    setDisabled(true);
    event.preventDefault();

    const requestBody = {
      checklist_id: checklist.id,
      category: category,
      name: name,
    };

    postRequest("/checklist_items", requestBody)
      .then((jsonResponse) => {
        dispatch(update(jsonResponse));
        setName("");
      })
      .finally(() => setDisabled(false));
  };

  return (
    <form id="new_checklist_item" onSubmit={handleSubmit}>
      <h3>New item</h3>
      <div className="row mb-3">
        <label
          className="col-auto col-form-label"
          htmlFor="checklist_item_name"
        >
          Name
        </label>
        <div className="col-auto">
          <input
            className="form-control"
            type="text"
            name="name"
            id="checklist_item_name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            disabled={disabled}
          />
        </div>
      </div>
      <div className="row">
        <label className="col-auto col-form-label" htmlFor="category">
          Category
        </label>
        <div className="col-auto">
          <input
            type="text"
            name="category"
            id="category"
            className="form-control mb-3"
            value={category}
            list="existing-categories"
            onChange={(e) => setCategory(e.target.value)}
            disabled={disabled}
          />
          <datalist id="existing-categories">{categoriesList}</datalist>
        </div>
      </div>
      <div className="row">
        <div className="offset-sm-1">
          <input
            type="submit"
            name="commit"
            value="Add Item"
            className="btn btn-primary"
            disabled={disabled}
          />
        </div>
      </div>
    </form>
  );
};
