import React, { useState } from "react";
import { ChecklistItemCategory } from "./checklist_item_category";
import { ChecklistItemForDragOverlay } from "./checklist_item_for_drag_overlay";
import {
  DndContext,
  DragOverlay,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragStartEvent,
  DragEndEvent,
} from "@dnd-kit/core";

import { sortableKeyboardCoordinates } from "@dnd-kit/sortable";

import { putRequest } from "/helpers";
import { Item } from "types";

import type { RootState } from "store";
import { useSelector, useDispatch } from "react-redux";
import { update } from "checklistSlice";

export const ChecklistItemCategories = () => {
  const [draggedItemId, setDraggedItemId] = useState("");
  const checklist = useSelector((state: RootState) => state.checklist.value);
  const categories = checklist.categories;
  const dispatch = useDispatch();

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const findItemById = (itemId: string): Item => {
    const item = categories
      .flatMap(({ items }) => items)
      .find((item) => item.id === itemId);

    if (!item) {
      throw new Error(`No item found itemId: ${itemId}`);
    }

    return item;
  };

  const updateItemPosition = (
    itemId: string,
    newPosition: number,
    newCategoryId: string,
  ) => {
    putRequest(`/checklist_items/${itemId}/position`, {
      position: newPosition.toString(),
      category_id: newCategoryId,
    }).then((jsonResponse) => dispatch(update(jsonResponse)));
  };

  const noCategories = categories.length == 1 && categories[0].name == null;
  const categoryListItems = categories.map((category) => (
    <li className="list-group-item" key={category.id}>
      <ChecklistItemCategory
        noCategories={noCategories}
        category={category}
        isRun={checklist.is_run}
        authorization={checklist.authorization}
      />
    </li>
  ));

  const handleDragStart = ({ active }: DragStartEvent) => {
    setDraggedItemId(active.id.toString());
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const activeItemId = event.active.id;
    const overItemId = event.over?.id;
    const overCategoryId = event.over?.data?.current?.sortable.containerId;

    if (activeItemId !== overItemId) {
      if (!activeItemId) {
        throw new Error(`No activeItemId: ${activeItemId}`);
      }

      if (!overItemId) {
        throw new Error(`No overItemId: ${overItemId}`);
      }

      const newIndex = findItemById(overItemId.toString()).position;

      updateItemPosition(activeItemId.toString(), newIndex, overCategoryId);
    }

    setDraggedItemId("");
  };

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
    >
      <ul className="list-group list-group-flush">{categoryListItems}</ul>
      <DragOverlay>
        {draggedItemId ? (
          <ChecklistItemForDragOverlay
            item={findItemById(draggedItemId)}
            isRun={checklist.is_run}
          />
        ) : null}
      </DragOverlay>
    </DndContext>
  );
};
